// Edits text `value` (if `operation` is passed) and repositions the `caret` if needed.
//
// Example:
//
// value - '88005553535'
// caret - 2 // starting from 0; is positioned before the first zero
// operation - 'Backspace'
//
// Returns
// {
// 	value: '8005553535'
// 	caret: 1
// }
//
// Currently supports just 'Delete' and 'Backspace' operations
//
export default function edit(value, caret, operation)
{
	switch (operation)
	{
		case 'Backspace':
			// If there exists the previous character,
			// then erase it and reposition the caret.
			if (caret > 0)
			{
				// Remove the previous character
				value = value.slice(0, caret - 1) + value.slice(caret)
				// Position the caret where the previous (erased) character was
				caret--
			}
			break

		case 'Delete':
			// Remove current digit (if any)
			value = value.slice(0, caret) + value.slice(caret + 1)
			break
	}

	return { value, caret }
}